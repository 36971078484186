import React, { useMemo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { CreativeV2, CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { isCustomUploadCreative, isMediaCreative, isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import '../styles/item.scss';

type Props = {
    creative: CreativeV2;
    editCreative: (creative: CreativeV2) => void;
    removeCreative: (creative: CreativeV2) => void;
};

const CreativeSelectorItem: React.FC<Props> = ({ creative, editCreative, removeCreative }) => {
    const thumbnail = (creative as CreativeV2Template)?.thumbnail || creative?.data?.thumbnail?.url || creative?.data?.url;

    const preview = useMemo(() => {
        if (isTemplateCreative(creative)) {
            return <img className="creative-selector-item__preview__img" src={thumbnail} alt={creative.title} />;
        } else if (isMediaCreative(creative)) {
            return <img className="creative-selector-item__preview__img" src={creative.data.url} alt={creative.title} />;
        } else if (isCustomUploadCreative(creative)) {
            if (creative.data.fileType === 'video') {
                return <video className="creative-selector-item__preview__video" src={creative.data.url} />;
            }
            return <img className="creative-selector-item__preview__img" src={creative.data.url} alt={creative.title} />;
        }
    }, [creative.data]);

    return (
        <div className="creative-selector-item">
            <div className="creative-selector-item__preview">{preview}</div>
            <div className="creative-selector-item__copy">
                <Typography noWrap variant="h5" color="textPrimary">
                    <Tooltip title={creative.title && creative.title.length > 20 ? creative.title : ''}>
                        <span>{creative.title}</span>
                    </Tooltip>
                </Typography>
                {isTemplateCreative(creative) && (
                    <Typography noWrap={true} variant="body2" color="textSecondary">
                        <Tooltip title={Translation.get(`sub_types.${creative.data.templateType}`, 'template-management')}>
                            <span> {Translation.get(`sub_types.${creative.data.templateType}`, 'template-management')} </span>
                        </Tooltip>
                    </Typography>
                )}
                {isMediaCreative(creative) && (
                    <Typography noWrap={true} variant="body2" color="textSecondary">
                        {creative.data.width}x{creative.data.height}
                    </Typography>
                )}
                {isCustomUploadCreative(creative) && (
                    <Typography noWrap={true} variant="body2" color="textSecondary">
                        {`${Translation.get(`labels.customUpload`, 'common')} - ${creative?.data?.subtitle}`}
                    </Typography>
                )}
            </div>
            {isTemplateCreative(creative) && (
                <IconButton onClick={() => editCreative(creative)} size="small" className="creative-selector-item__edit">
                    <Icon>edit</Icon>
                </IconButton>
            )}
            <IconButton onClick={() => removeCreative(creative)} size="small" className="creative-selector-item__delete">
                <Icon>delete</Icon>
            </IconButton>
        </div>
    );
};

export default CreativeSelectorItem;
